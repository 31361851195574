.sharpay-login-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
	//margin-right: 30px;
	padding: 0 30px;

	.sharpay-login-form {
		.sharpay-login-title {
			font-size: 18px;
			color: #333;
			font-weight: bold;
			margin-bottom: 29px;
		}

		.sharpay-login-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 33px;

			label {
				min-width: 110px;
				color: #8e8e93;
			}

			.sharpay-login-input {
				width: 100%;
				height: 40px;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					width: 20px;
					height: 20px;
					top: 50%;
					margin-top: -10px;
					left: 20px;
					pointer-events: none;
				}

				&.login-input {
					&:before {
						background: url("/img/outline-account-circle-24-px.svg") no-repeat center;
					}
				}

				&.password-input {
					&:before {
						background: url("/img/outline-lock-24-px.svg") no-repeat center;
					}
				}

				input {
					box-sizing: border-box;
					width: 100%;
					height: 40px;
					font-size: 12px;
					padding: 10px 20px 10px 55px;
					border-radius: 25px;
					box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
					background-color: #ffffff;
					border: none;
					outline: none;
				}
			}
		}
	}

	.sharpay-login-buttons {
		display: flex;
		justify-content: flex-end;

		&.mobile-buttons {
			/*position: absolute;
			bottom: 43px;
			left: 50%;
			margin-left: -100px;*/
			margin-top: 30px;
		}

		.button-disabled {
			background: #e6e6e6 !important;
		}

		button {
			padding: 9px 16px 8px 16px;
			border: none;
			border-radius: 20px;
			background: none;
			outline: none;
			margin-left: 20px;
			cursor: pointer;

			&:last-child {
				background-color: #4cd964;
				color: #fff;
			}
		}
	}
}

.content-mobile {
	.sharpay-login-wrapper {
		padding: 0 20px;

		.sharpay-login-form {
			.sharpay-login-row {
				flex-direction: column;
				align-items: flex-start;

				label {
					margin-bottom: 8px;

				}
			}
		}
	}

	/*.sharpay-login-buttons {
		display: none;
	}*/
}
