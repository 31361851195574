.sharpay-payment {
	height: 100%;

	.accounts-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		//margin-right: 23px;

		.accounts-head {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 30px;

			.accounts-title {
				font-size: 18px;
				color: #333;
				font-weight: bold;
				margin-bottom: 9px;
			}

			.accounts-exchange {
				font-size: 13px;
				font-weight: bold;
				margin-bottom: 9px;
			}
		}

		.accounts {
			overflow-y: auto;
			//margin-right: 7px;
			flex: 1;
			//padding: 0 5px;

			.acc-wrap {
				padding: 0 30px;

				.account {
					display: flex;
					padding: 0 20px;
					align-items: center;
					border-radius: 20px;
					height: 65px;
					margin: 20px 0;
					border: solid 2px #efeff4;;

					&.selected-account {
						border: solid 2px transparent;;
						box-shadow: 0 5px 27px 0 #c2ccea;
					}

					.form_radio {
						width: 100%;
					}

					.form_radio input[type=radio] {
						display: none;
					}

					.form_radio label {
						display: flex;
						cursor: pointer;
						position: relative;
						padding-left: 25px;
						margin-right: 0;
						line-height: 18px;
						user-select: none;
					}

					.form_radio label:before {
						content: "";
						display: inline-block;
						width: 17px;
						height: 18px;
						position: absolute;
						left: 0;
						top: 50%;
						margin-top: -9px;
						background: url(/img/radio-button-unchecked.svg) 0 0 no-repeat;
					}

					/* Checked */
					.form_radio input[type=radio]:checked + label:before {
						background: url(/img/radio-button-checked-24-px.svg) 0 0 no-repeat;
					}

					/* Hover */
					.form_radio label:hover:before {
						filter: brightness(120%);
					}

					/* Disabled */
					.form_radio input[type=radio]:disabled + label:before {
						filter: grayscale(100%);
					}

					.form_radio {
						.account-icon {
							width: 25px;
							height: 25px;
							margin-right: 15px;
						}

						.account-number {
							line-height: 25px;
							margin-right: 15px;
						}

						.account-currency {
							padding: 0 3px;
							line-height: 25px;
							background: #4cd964;
							color: #fff;
							font-size: 13px;
							border-radius: 6px;
						}

						.account-balance {
							line-height: 25px;
							position: absolute;
							right: 0;
							height: 24px;
							top: 50%;
							margin-top: -12px;
							min-width: 121px;
							display: flex;
							justify-content: space-around;

							.account-balance-text {
								margin-right: 3px;
							}
						}
					}
				}
			}
		}

		.accounts-buttons {
			align-self: flex-end;
			padding: 0 30px;

			&.mobile-buttons {
				/*position: absolute;
				bottom: 10px;
				left: 50%;
				margin-left: -100px;*/
				margin-top: 30px;
			}

			.button-disabled {
				background: #e6e6e6 !important;
			}

			button {
				padding: 9px 16px 8px 16px;
				border: none;
				border-radius: 20px;
				background: none;
				outline: none;
				margin-left: 20px;
				cursor: pointer;

				&:last-child {
					background-color: #4cd964;
					color: #fff;
				}
			}
		}

		.summary-otp-wrapper {
			flex: 1;
			margin-bottom: 25px;
			padding: 0 30px;

			.summary-otp {
				position: relative;
				height: 40px;

				&:before {
					content: '';
					width: 18px;
					height: 22px;
					background: url("/img/shield.svg") no-repeat;
					position: absolute;
					top: 50%;
					margin-top: -11px;
					left: 15px;
					pointer-events: none;
				}

				.resend-otp {
					position: absolute;
					right: 15px;
					top: 50%;
					margin-top: -11px;
					width: 16px;
					height: 22px;
					background: url("/img/autorenew-24-px.svg") no-repeat;
					cursor: pointer;

					&.resended {
						-webkit-animation: spin 1.5s linear infinite;
						-moz-animation: spin 1.5s linear infinite;
						animation: spin 1.5s linear infinite;
						@-moz-keyframes spin {
							100% {
								-moz-transform: rotate(360deg);
							}
						}
						@-webkit-keyframes spin {
							100% {
								-webkit-transform: rotate(360deg);
							}
						}
						@keyframes spin {
							100% {
								-webkit-transform: rotate(360deg);
								transform: rotate(360deg);
							}
						}
					}
				}

				input {
					border: none;
					outline: none;
					padding: 11px 11px 11px 43px;
					border-radius: 25px;
					box-shadow: 0 4px 27px 0 rgba(0, 0, 128, 0.08);
					line-height: 18px;
					width: 100%;
					box-sizing: border-box;
				}
			}
		}
	}
}

.mobile {
	.accounts-wrapper {
		.accounts-head {
			padding: 0 20px;
		}

		.summary-wrapper {
			padding: 0 20px;
		}
		.summary-otp-wrapper {
			padding: 0 20px;
		}

		.accounts {
			.acc-wrap {
				padding: 0 20px;
			}
		}

		.accounts-buttons {
			padding: 0 20px;
		}
	}
}

label {
	-webkit-tap-highlight-color: transparent;
}
