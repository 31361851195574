.topbar {
	display: flex;
  height: 70px;
  border-bottom: 2px solid #efeff4;
	box-sizing: border-box;

	.topbar-inner {
		display: flex;
		flex: 1;
		justify-content: space-evenly;
		align-items: center;

		.step {
			display: flex;
			align-items: center;
			height: 100%;

			&.previous-step {
				.step-number {
					.step-number-inner {
						background-color: #4cd964;
					}
				}
			}

			&.current-step {
				position: relative;

				&:after {
					content: '';
					width: 100%;
					height: 2px;
					position: absolute;
					bottom: -2px;
					left: 0;
					background-color: #4cd964;
				}

				.step-number {
					.step-number-inner {
						background-color: #4cd964;
					}
				}
			}

			.step-number {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 18px;
				font-weight: bold;
				width: 30px;
				height: 40%;

				.step-number-inner {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #e6e6e6;
					width: 30px;
					height: 30px;
					border-radius: 6px;
				}
			}

			.step-title {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				font-weight: bold;
				color: #333333;
				margin-left: 10px;
				height: 60%;
			}
		}
	}
}

#root {
	.topbar-mobile {
		height: 104px;

		.topbar-inner {
			text-align: center;
			margin: 0 20px 0 20px;
			justify-content: space-between;
          min-height: 102px;
		}

		.step {
			flex-direction: column;

			.step-title {
				max-width: 50px;
				margin-left: 0;
			}
		}
	}
}
