.summary-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  flex: 1;
  padding: 0 30px;
  overflow: hidden;
}

.summary-inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  height: 100%;
  box-sizing: border-box;
}

.summary-head {
  display: flex;
  height: 65px;
  padding: 0 20px;
  align-items: center;
  position: relative;
  border-bottom: 2px solid #f3f3f7;
  gap: 10px;

  img {
    height: 40px;
  }
}

.summary-details {
  padding: 20px;
}

.summary-details-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;

  .summary-details-text-container {
    flex: 1;
    overflow: hidden;

    .summary-details-text-inner {
      height: 100%;
      overflow-y: auto;
      padding-right: 10px;
    }
  }

  .summary-details-text-link {
    margin-top: 20px;
    text-align: right;
  }
}
