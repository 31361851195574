.sidebar {
  color: #333333;

  &.sidebar-desktop {
    height: 100%;
    overflow: hidden;
    flex: 0 0 359px;

    .sidebar-accordion-head {
      display: none;
    }

    .sidebar-inner {
      display: flex;
      height: 100%;
      box-sizing: border-box;
      flex-direction: column;
      padding: 40px;
    }
  }

  &.sidebar-mobile {
    flex: 0 0 auto;
    min-height: 54px;
    box-sizing: border-box;
    overflow: hidden;

    .sidebar-accordion-head {
      height: 100%;
      box-sizing: border-box;
      border-bottom: 2px solid #efeff4;
      font-size: 15px;
      font-weight: bold;

      &.head-close {
        height: 100%;
      }

      &.head-open {
        height: 54px;

        .details-icon {
          transform: rotate(90deg);
        }
      }

      .sidebar-head-inner {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 20px;

        .details-icon {
          width: 10px;
          height: 10px;
          background-image: url("/img/triangle.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }

    }

    .sidebar-inner {
      padding: 20px;

      &.sidebar-open {
        display: block;
        background-color: #efeff4;

        .sidebar-main {
          font-size: 24px;
          margin-bottom: 20px;
        }

        .sidebar-block {
          font-size: 10px;
          max-width: 100%;
          margin-bottom: 20px;
          line-height: 15px;
        }
      }

      &.sidebar-close {
        display: none;
      }
    }
  }

  .sidebar-inner {

    .sidebar-main {
      font-size: 48px;
      font-weight: 600;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;

      .sub-amount {
        font-size: 16px;
        color: #e39f66;
      }
    }

    .sidebar-block {
      margin-bottom: 30px;
      max-width: 256px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;

      .sidebar-block-title {
        font-weight: bold;
      }

      .sidebar-block-text {
        word-break: break-all;
      }

      .sidebar-block-info {
        margin: 0;
      }
    }
  }
}


