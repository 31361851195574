@keyframes clockwiseSpin {
  from  {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.status-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  //margin-right: 23px;

  .status-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;

    .status-title {
      display: flex;
      width: 100%;
      font-size: 18px;
      color: #333;
      font-weight: bold;
      margin-bottom: 29px;

      .status-text {
        margin-left: 5px;
      }
    }

    .status-icon {
      width: 20px;
      margin-left: 10px;

      .clockwiseSpin {
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-name: clockwiseSpin;
        animation-timing-function: linear;
      }
    }
  }

  .status-button {
    align-self: flex-end;
    padding: 0 30px;

    button {
      padding: 9px 16px 8px 16px;
      border: none;
      border-radius: 20px;
      background-color: #4cd964;
      outline: none;
      margin-left: 20px;
      color: #fff;
      cursor: pointer;

      &[disabled] {
        background: #e6e6e6;
      }
    }
  }
}

.content-mobile {
  .status-wrapper {
    .status-head {
      padding: 0 20px;
    }

    .summary-wrapper {
      padding: 0 20px;
    }

    .status-button {
      padding: 0 20px;
    }
  }
}
