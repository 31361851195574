.content {
	display: flex;
	flex-direction: column;
	flex: 1;
	border-top-left-radius: 30px;
	box-shadow: 0 25px 40px 0 #c2ccea;
	//padding: 30px 0 30px 30px;
	padding: 30px 0;
	box-sizing: border-box;

	.list-wrapper {
		height: 100%;

		.content-title {
			padding: 0 30px;
		}

		.accordion {
			padding: 15px 30px;
		}
	}

	.accordion-item {
		display: block;
		width: 100%;
	}

	.panel {
		background-color: white;
		display: none;
		overflow: hidden;

		.panel-inner {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&.content-mobile {
		//padding: 20px 0 20px 20px;
		box-shadow: none;

		.content-title {
			padding: 0 20px;
			font-size: 15px;
		}

		.accordion {
			padding: 10px 20px;
			font-size: 12px;
		}

		.content-inner {
			.scrollable-wrapper {
				.scrollable-container {
					.scrollable-content {
						.payments-wrapper {
							.payment-method {
								margin: 20px;
								max-width: 67px;

								.payment-method-icon {
									width: 67px;
									height: 67px;

									img {
										width: 40px;
										height: 40px;
									}
								}

								.payment-method-name {
									font-size: 10px;
								}
							}
						}
					}
				}
			}
		}

		.accounts {
			.account {
				padding: 0 7px !important;
			}
		}
	}

	.content-title {
		color: #333;
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 4px;
	}

	.scrollable {
		display: block;
		height: 100%;
		min-height: 0;

		.scrollable-wrapper {
			position: relative;
			width: 100%;
			height: 100%;

			.scrollable-container {
				-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
				overflow: auto;
				width: 100%;
				height: 100%;

				.scrollable-content {
					position: relative;
					min-height: 100%;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;

					.payments-wrapper {
						display: flex;
						flex-wrap: wrap;

						.payment-method {
							cursor: pointer;
							display: flex;
							flex-direction: column;
							align-items: center;
							margin: 30px;
							max-width: 100px;

							.payment-method-icon {
								display: flex;
								justify-content: center;
								align-items: center;

								width: 100px;
								height: 100px;
								border-radius: 20px;
								box-shadow: 0 5px 27px 0 #c2ccea;

								&:hover {
									box-shadow: 0 5px 27px 0 #8297d4;
								}

								img {
									width: 60px;
								}
							}

							.payment-method-name {
								margin-top: 11px;
							}
						}
					}
				}
			}
		}
	}
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #e1e1e1;
	border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #888;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
	background-color: #fff;
	color: #000;
	cursor: pointer;
	//padding: 18px 0;
	width: 100%;
	text-align: left;
	border: none;
	outline: none;
	transition: 0.4s;
	text-transform: capitalize;
	font-size: 16px;
	font-weight: bold;
}

button.accordion {
	&.active {
		&:after {
			content: "\2212";
		}
	}

	&:after {
		content: '\002B';
		color: #000;
		font-weight: bold;
		float: left;
		margin-right: 5px;
	}
}
