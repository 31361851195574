.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f5fe;

  #locale-switcher-wrapper {
    width: 1040px;

    &.mobile-switcher {
      width: 100%;
      background: white;
    }

    #locale-switcher-inner {
      margin: 10px 20px;
      display: flex;
      justify-content: flex-end;

      .switcher {
        border: none;
        background: transparent;

        .switcher-item {
          background: white;
          color: grey;
        }
      }
    }
  }

  .layout {

    .loader-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &.desktop {
      width: 1040px;
      height: 706px;
      border-radius: 30px;
      box-shadow: 0 25px 40px 0 #c2ccea;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      background: #fff;

      .main-content {
        height: 100%;
        display: flex;
        min-height: 0;
      }
    }
  }

  .layout {
    &.mobile {
      width: 100%;
      height: calc(100% - 37px);
      display: flex;
      flex-direction: column;
      background: #fff;

      .main-content {
        display: flex;
        flex-direction: column;
        height: 100%;

      }
    }
  }
}

.bold-text {
  font-weight: bold;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.order-amount-label {
  cursor: pointer;
  display: flex;
  margin: 20px 30px;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #efeff4;
  border-radius: 20px;
  padding: 0 20px;
  height: 65px;

  &.selected-order {
    border: solid 2px transparent;;
    box-shadow: 0 5px 27px 0 #c2ccea;
  }

  .order-amount-inner {
    display: flex;
    align-items: center;
    gap: 5px;

    input[type='radio'] {
      cursor: pointer;
    }
  }
}

.order-amounts-wrapper {
  overflow: hidden;

  .order-amounts-inner {
    overflow: auto;
  }
}

.order-amounts-wrapper, .requisites-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;

  .order-amounts-inner {
    width: 100%;
  }

  .accounts-buttons {
    margin: 0 30px;

    button {
      padding: 9px 16px 8px;
      border: none;
      border-radius: 20px;
      background: none;
      outline: none;
      margin-left: 20px;
      cursor: pointer;

      &:last-child {
        background-color: #4cd964;
        color: #fff;
      }

      &.button-disabled {
        background: #e6e6e6 !important;
      }
    }
  }
}

.requisites-text {
  margin: 0 30px;
}

.req-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .req-action {
    display: flex;
    margin: 10px 30px;
    justify-content: end;
    gap: 8px;
  }

  .req-inner {
    height: calc(100% - 64px);
    margin: 0 30px;
    overflow-y: auto;
    overflow-x: hidden;

    .bank-requisites {
      padding: 20px;
      border-radius: 20px;
      background: #f9f9f9;
      margin-bottom: 20px;
    }

    .req-item {
      .req-qr {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .req-item-title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 12px;
    display: inline-block;
  }

  .req-row {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;

    .req-name {
      font-weight: bold;
    }

    .req-value {
      display: flex;
      text-align: right;
    }
  }

  .divider {
    background: #D9D9D9;
    height: 1px;
    margin: 20px 0;
  }
}

.copy-button {
  width: 14px;
  height: 16px;
  display: inline-block;
  background: url(/img/icons/file-copy-24-px.svg) 0 0 no-repeat;
  cursor: pointer;
  margin-left: 7px;
  position: relative;

  .copy-hint {
    opacity: 0;
    transition: opacity 1s ease-out;
    font-size: 12px;
    padding: 3px 6px;
    color: #fff;
    position: absolute;
    left: 0;
    bottom: -31px;
    background-color: #4cd964;
    border: 1px solid #4cd964;
    border-radius: 6px;

    &.copy-hint-visible {
      opacity: 1;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      top: -5px;
      left: 3px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #4cd964;
    }
  }
}

.app-copy-button-wrapper {
  position: relative;

  .copy-button-hint {
    transition: opacity 400ms ease-out;
    font-size: 12px;
    padding: 3px 6px;
    color: #fff;
    position: absolute;
    left: -57px;
    bottom: -2px;
    background-color: #4cd964;
    border: 1px solid #4cd964;
    border-radius: 6px;
  }
}

.req-title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin: 0 30px 10px 30px;

  .sub-req-title {
    font-size: 15px;
  }
}

.app-btn {
  padding: 9px 16px 8px;
  border: none;
  border-radius: 20px;
  background: none;
  outline: none;
  cursor: pointer;
  background: #4cd964;
  color: #fff;

  &.cancel {
    color: black;
    background: none;
  }
}

.sidebar-img {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 5px;

  &.default {
    background: #d073ff99;
    border-radius: 20px;
  }

  img {
    width: 60px;
  }
}


.important-message {
  background: #ffe8ce;
  border: 1px solid #f5e4d6;
  border-radius: 6px;
  margin: 10px;
  padding: 10px;
  font-size: 12px;
}

.with-copy {
  display: flex;
  gap: 5px;
}

.order-params {
  input, .react-tel-input {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
  }
}

.warning-text {
  .text-block {
    margin-bottom: 10px;

    p {
      margin: 0;
    }
  }
}

.content-mobile {
  .req-wrapper {
    .req-inner {
      .req-item {
        .req-row {
          flex-direction: column;

          .req-value {
            justify-content: space-between;
            text-align: left;
          }
        }
      }
    }
  }
}